<template>
  <div class="sidebar-layout" :class="{ expand: sidebar }">
    <div
      class="expand-box"
      @click="foldHandel"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
    >
      <template v-if="!iconState">
        <div class="icon-box"></div>
      </template>
      <template v-else>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="sidebar ? '展开' : '收起'"
          placement="right"
          popper-class="my-tooltip-popper"
        >
          <div class="expand-icon-box">
            <svg-icon
              :icon-class="'expand-' + (sidebar ? 'off' : 'on')"
              class="expand-icon"
            ></svg-icon>
          </div>
        </el-tooltip>
      </template>
    </div>
    <div class="menu-box">
      <template v-for="(value, key, index) in permission_routes" :key="key">
        <template v-if="value.value.length !== 0">
          <div
            v-show="value.label !== '其他' && index !== 0"
            class="line"
            :class="{ 'w-16': sidebar }"
          ></div>
          <div v-show="!sidebar && value.label !== '其他'" class="title-style">
            {{ value.label }}
          </div>
          <el-menu
            :collapse-transition="false"
            :default-active="activeMenu"
            background-color="transparent"
            :collapse="sidebar"
            :default-openeds="value.defaultOpeneds || []"
            class="menu"
          >
            {{ permission_route }}
            <sidebar-item
              v-for="permission_route in value.value"
              :key="permission_route.path"
              :active-menu="activeMenu"
              :item="permission_route"
              :base-path="permission_route.path"
            />
          </el-menu>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
import SidebarItem from "./SidebarItem";
import { permissionStore } from "@/store/permission";
import { appCounterStore } from "@/store/app";
import { computed, ref, unref } from "vue";
import { useRoute } from "vue-router";

const store = permissionStore();
const appStore = appCounterStore();
const route = useRoute();
const iconClass = ref("left");
const iconState = ref(false);
const hashrate = ["resource", "order"];
const application = [
  "data-module",
  "model",
  "train",
  "task",
  "inference",
  "application-center",
  "knowledge"
];
const system = ["user", "logs"];
const modelRouter = [...hashrate, ...application, ...system];
const permission_routes = computed(() => {
  let obj = {
    hashrate: {
      label: "GPU算力云",
      value: [],
      includeModel: hashrate,
      defaultOpeneds: ["/resource"]
    },
    application: {
      label: "大模型应用服务",
      value: [],
      includeModel: application
    },
    system: {
      label: "系统设置",
      value: [],
      includeModel: system
    },
    other: {
      label: "其他",
      value: []
    }
  };
  const routerList = store.getRoutes;
  for (let key in obj) {
    let includeModel = key !== "other" ? obj[key]["includeModel"] : modelRouter;
    obj[key].value = routerList.filter((item) => {
      return key !== "other"
        ? includeModel.includes(item.name)
        : !includeModel.includes(item.name);
    });
  }
  return obj;
});
const sidebar = computed(() => appStore.sidebar);
const activeMenu = computed(() => {
  const { meta, path } = route;
  if (meta.activeMenu) {
    return meta.activeMenu;
  }
  return path;
});
const foldState = computed(() => unref(appStore.getSidebar));

const foldHandel = () => {
  if (foldState.value) {
    iconClass.value = "left";
    appStore.setSidebar(false);
  } else {
    iconClass.value = "right";
    appStore.setSidebar(true);
  }
};
const mouseenter = () => {
  iconState.value = true;
};
const mouseleave = () => {
  iconState.value = false;
};
</script>
<style lang="scss" scoped>
.expand-box {
  position: absolute;
  top: calc(50% - 20px);
  right: -24px;
  z-index: 1000;
  cursor: pointer;
  .expand-icon-box {
    height: 40px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }
  .expand-icon {
    font-size: 24px;
  }
}
.menu-box {
  padding: 4px 6px 0 6px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 200px;
}
.menu-box::-webkit-scrollbar-thumb {
  background: transparent;
}
.icon-box {
  width: 5px;
  height: 24px;
  width: 5px;
  height: 24px;
  margin: 8px 10px 8px 8px;
  border-radius: 13px;
  background-color: $neutral-neutral-40;
}

.expand {
  width: $sider-width-expand !important;
  :deep().el-menu-item {
    justify-content: center;
  }
  :deep().menu-title {
    margin-left: 0;
  }
}
.sidebar-layout {
  position: relative;
  width: $sider-width;
  border-right: 1px solid #e4e8ed;
  flex-shrink: 0;
  .menu {
    display: grid;
  }
}
.el-menu {
  border-right: none !important;
}
.title-style {
  padding: 10px 18px 7px 20px;
  line-height: 18px;
  font-size: 13px;
  text-align: left;
  color: $neutral-neutral-70;
  /* margin-bottom: 6px; */
}
.line {
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  background-color: #d8dfe7;
  margin: 14px 18px 14px 20px;
  &:first-child {
    display: none;
  }
}
.w-16 {
  width: 16px;
}
</style>
