<template>
  <div class="breadcrumb" :class="{ expand: sidebar }">
    <div class="display-flex align-items">
      <template v-for="item in breadcrumb" :key="item.name">
        <svg-icon
          v-if="item.title === '控制台' && item.breadcrumb !== false"
          icon-class="home-new"
          class-name="home-icon"
        ></svg-icon>
        <span v-if="item.activiteMenuTitle" class="text">{{
          item.activiteMenuTitle
        }}</span>
        <span v-if="item.breadcrumb !== false && item.title" class="text">
          {{ item.title }}
        </span>
      </template>
    </div>
  </div>
</template>
<script setup>
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { appCounterStore } from "@/store/app";
  const appStore = appCounterStore();
  const route = useRoute();

  const breadcrumb = computed(() => {
    let matched = [];
    for (let item of route.matched) {
      matched.push(item.meta);
    }
    return matched;
  });
  const sidebar = computed(() => appStore.sidebar);
</script>
<style lang="scss" scoped>
  .breadcrumb {
    position: absolute;
    top: calc(50% - 10px);
    left: calc($sider-width + $padding-left);
    line-height: 20px;
    color: $neutral-neutral-60;
    margin-right: 7px;
    .text:not(:last-child):after {
      margin-left: 7px;
      content: "/";
    }
    .text:last-child {
      color: $neutral-neutral-80;
    }
    .text + .text {
      margin-left: 7px;
    }
  }
  .left-text {
    line-height: 20px;
    color: $neutral-neutral-60;
    margin-right: 7px;
  }
  .breadcrumb.expand {
    left: calc($sider-width-expand + $padding-left);
  }
  .right-text {
    line-height: 20px;
    color: $neutral-neutral-80;
  }
  .home-icon {
    font-size: 14px !important;
    margin-right: 7px;
    color: $neutral-neutral-80;
  }
</style>
