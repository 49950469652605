export const permissionAlias = {
    computing: [],
    models: [],
    settings: []
};
export const hashrate = ["resource", "order"];
export const application = [
    "data-module",
    "model",
    "train",
    "task",
    "inference",
    "application-center",
    "application",
    "knowledge"
];
export const system = ["user", "logs", "quota", "finance"];
