import { defineStore } from "pinia";
import { getToken, setToken, getUserInfo, setUserInfo, getAccountInfo, setAccountInfo, removeAccountInfo, getRefreshToken, setRefreshToken, getRoles, setRoles } from "@/utils/auth";
import { login, logout, loginSub, refreshToken as refreshTokenApi } from "@/service/login";
import { getAccessKey } from "@/service/objectStorage";
const clearUserInfo = [
    "userInfo",
    "RefreshToken",
    "Roles",
    "loginState",
    "Token",
    "accountInfo"
];
export const useCounterStore = defineStore("user", {
    state: () => ({
        userInfo: getUserInfo(),
        token: getToken(),
        refreshTokenValue: getRefreshToken(),
        roles: getRoles() || [],
        accountInfo: getAccountInfo() || {},
        files: [],
        accessKeyInfo: {}
    }),
    getters: {
        getFiles: (state) => state.files,
        getAccessKeyInfo: (state) => state.accessKeyInfo,
        getRoles: (state) => state.roles,
        getStoreToken: (state) => state.token,
        getUserInfo: (state) => state.userInfo,
        getRefreshToken: (state) => state.refreshTokenValue,
        getAccountInfo: (state) => state.accountInfo
    },
    actions: {
        setFileList(data) {
            return new Promise((resolve) => {
                this.files = this.files.concat(data);
                resolve(this.files);
            });
        },
        removeFiles(index) {
            return new Promise((resolve) => {
                this.files.splice(index, 1);
                resolve(this.files);
            });
        },
        login(params) {
            return new Promise((resolve, reject) => {
                login(params)
                    .then((res) => {
                    this.setValue(res.data);
                    resolve(res);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        },
        logout() {
            return new Promise((resolve, reject) => {
                logout()
                    .then((res) => {
                    resolve(res);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        },
        getAccessInfo(params) {
            return new Promise((resolve, reject) => {
                getAccessKey(params)
                    .then((res) => {
                    this.accessKeyInfo = {
                        endpoint: "https://storage.cloud.test.ebtech-inc.com",
                        forcePathStyle: true,
                        region: res.data.s3_region,
                        credentials: {
                            accessKeyId: res.data.access_key_id,
                            secretAccessKey: res.data.access_key_secret,
                            endpoint: "storage.cloud.test.ebtech-inc.com"
                        }
                    };
                    resolve(this.accessKeyInfo);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        },
        loginSub(params) {
            return new Promise((resolve, reject) => {
                loginSub(params)
                    .then((res) => {
                    resolve(res);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        },
        refreshToken() {
            return new Promise((resolve, reject) => {
                const params = {
                    refresh_token: this.refreshTokenValue
                };
                refreshTokenApi(params)
                    .then((res) => {
                    this.token = res.data.access_token;
                    this.refreshTokenValue = res.data.refresh_token;
                    setToken(this.token);
                    setRefreshToken(this.refreshTokenValue);
                    resolve("");
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        },
        resetToken() {
            return new Promise((resolve) => {
                this.token = "";
                this.roles = [];
                for (const key of clearUserInfo) {
                    localStorage.removeItem(key);
                }
                sessionStorage.clear();
                resolve("");
            });
        },
        setValue(value) {
            this.$patch({
                token: value.access_token,
                refreshTokenValue: value.refresh_token,
                userInfo: value,
                roles: [value.type === "admin" ? value.type : "master"]
            });
            setRoles(this.roles);
            setToken(this.token);
            setUserInfo(value);
            setRefreshToken(value.refresh_token);
        },
        storeSetAccountInfo(value) {
            this.accountInfo = value;
            setAccountInfo(value);
        },
        storeRemoveAccountInfo() {
            this.accountInfo = {};
            removeAccountInfo();
        }
    }
});
